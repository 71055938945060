<template>
  <div class="container">
    <section class="for-parti">
      <div class="row">
        <div class="col-lg-6">
          <div class="parti-intro">
            <div class="d-flex">
              <div class="title-intro">Committe</div>
              <div class="mx-2">:</div>
              <div class="intro-parti-desc">
                <p class="font-medium">{{ header.committee }}</p>
              </div>
            </div>
            <div class="d-flex">
              <div class="title-intro">Subject Topic</div>
              <div class="mx-2">:</div>
              <div class="intro-parti-desc">
                <p class="font-medium">{{ header.subject_topic }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <router-link class="btn btn-action-speaker" :to="`/simulation/${$route.params.slug}#participant`" style="float: right">
            Back to Position Paper
          </router-link>
        </div>
      </div>

      <position-paper-list :room="room"></position-paper-list>

      <chat :room="room"></chat>

    </section>
  </div>
</template>

<script>
import PositionPaperList from './PositionPaperComponent/PositionPaperTable.vue';
import Chat from './PositionPaperComponent/Chat.vue';

export default {
  name: "PositionPaperDetail",
  components: {
    PositionPaperList,
    Chat
  },
  data() {
    return {
      room: {},
      header: {},
      participants: []
    }
  },
  mounted() {
    this.getRoomDetail()
  },
  methods: {
    getRoomDetail() {
      this.$axios.get(`/master/room/overview_detail/${this.$route.params.slug}`)
        .then(response => {
          this.room = response.data.data
          this.getRoomDetail2()
        });
    },
    getRoomDetail2() {
      this.$axios.get(`/master/room/${this.room.id}`)
        .then(response => {
          this.room2 = response.data.data
          this.getHeader()
        })
    },
    getHeader() {
      this.$axios.get(`/master/simulation/participant/${this.room.id}/detail`)
        .then(response => {
          this.header = response.data.data
        })
    }
  }
}
</script>
